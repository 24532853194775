<template>
  <div>
    <div class="panel panel-default mb-3">
      <div class="panel-heading">
        <h5 class="car-title mb-0">
          #{{ car.id }}
          <strong class="margin-l-5">
            {{ car.title }}
          </strong>

          <router-link :to="{ name: 'task.create', params: {car: car }}"
                       class="btn pull-right btn-success"
                       title="Выбрать авто"
                       type="button">
            <i class="fa fa-check-square"></i>
          </router-link>

          <a :href="$clientAppUrl + '/car/' + car.slug" :title="car.slug"
             style="font-size: 1rem; margin-right: 6px;"
             class="btn btn-info pull-right" target="_blank">
            <i class="fas fa-external-link-square-alt"></i>
          </a>
        </h5>
        <span class="font-medium font-small-xxs mt-0">
          <span v-if="car.owner_type === 'client'" class="label label-primary font-medium">
            Клиент
          </span>
          <span v-if="car.owner_type === 'company'" class="label label-primary font-medium">
            Компания
          </span>
          <span v-if="car.owner_type === 'exchange'" class="label label-primary font-medium">
            Обмен/выкуп
          </span>
        </span>
        <span class="label label-success font-medium font-small-xxs" style="padding: 2px 3px; margin-left: 5px;">
          {{ car.location === "owner" ? "У владельца" : "В салоне" }}
        </span>
      </div>
      <div class="panel-body mb-2">
        <div class="row">
          <div class="col-12 col-lg-5">
            <img v-if="car.main_photo" :src="car.main_photo" class="img-fluid" :alt="car.title"
                 style="min-height: 100px;">
            <img :alt="car.title" class="img-fluid" src="../../../assets/img/no-car-photo-240-140.png" v-else>

            <ul class="list-group list-group-unbordered">
              <li class="list-group-item no-border pt-0 pb-0">
                <strong class="font-scale-1">{{ car.price }} $</strong>
                <small v-if="car.old_price && car.is_top_price"
                       class="font-medium text-red text-muted margin-l-5 font-small">
                  {{ car.old_price }}$
                </small>
              </li>
              <li class="list-group-item no-border medium-line-height text-muted font-small pb-1 pt-0">
                <span class="font-medium">{{ car.creator.name }}</span>,
                {{ car.creator.branch ? (car.creator.branch.title + ', ') : '' }} ID {{ car.creator.id }}
              </li>
            </ul>
          </div>
          <div class="col-12 col-lg-7">
            <ul class="list-group list-group-unbordered">
              <li class="list-group-item pb-1">
                <strong>{{ car.branch.title }}</strong>
                <small v-if="car.branch.address">
                  ({{ car.branch.address }})
                </small>
                <small style="letter-spacing: -.035rem;">
                  <strong v-if="car.specification.engine_title" class="text-black font-small">
                    <span class="font-light">/</span> {{ car.specification.engine_title }}
                  </strong>
                  / {{ car.specification.fuel.title }}
                  / {{ car.specification.gearbox.title }}
                  / {{ car.specification.race }} тыс. км.
                  / {{ car.specification.transmission.title }} привод
                  / {{ car.specification.color.title }}
                  / <strong class="text-black font-small">{{ car.year }}</strong> г.
                </small>
              </li>
              <li class="list-group-item" style="border-bottom: 0; padding-bottom: 0; text-align: right;">
                <clipboard-block :data="car.specification.reg_number" custom_class="btn-default"/>
                <span class="label label-primary margin-r-5">
                  <strong style="font-size: 15px; letter-spacing: .03rem;">
                    {{ car.specification.reg_number }}
                  </strong>
                </span>

                <span class="label label-primary margin-l-5">
                  <strong style="font-size: 14px; letter-spacing: .001rem;">
                    {{ car.specification.vin_code }}
                  </strong>
                </span>
                <clipboard-block :data="car.specification.vin_code" custom_class="btn-default"/>
              </li>
            </ul>
          </div>
          <div v-if="car.sold"
               class="col-12"
               :style="car.sold ? 'background-color: rgba(40, 167, 69, 0.25) !important' : ''">
            Продано за
            <strong class="font-scale-1">
              {{ car.sold.sum }}$
            </strong>
            владельцу
            <strong class="font-scale-1">
              {{ car.sold.owner_sum }}$
            </strong>
            комиссия
            <strong class="font-scale-1">
              {{ (car.sold.sum - car.sold.owner_sum) }}$
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipboardBlock from './../../../components/blocks/clipboard-block.vue';

export default {
  name: 'car-task-item',

  components: {
    ClipboardBlock,
  },

  props: {
    car: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },
}
</script>

<style scoped>

</style>
